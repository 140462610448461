<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Imagens Banner - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn v-if="bannerphoto.banner" color="blue-grey" class="black--text" :to="{name: 'bannerphotos', params: {bnnr_id: bannerphoto.banner.bnnr_id}}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-banner-photo :bannerphoto="bannerphoto" :update="true"></form-banner-photo>
</span>
</template>

<script>
import FormBannerPhotoComponent from "./partial/FormBannerPhotoComponent";

export default {
    name: "EditBannerPhotoComponent",
    created() {
        this.loadBannerPhoto()
    },
    props: {
        bnnr_ft_id: {
            require: true
        }
    },
    data() {
        return {
            bannerphoto: {
                bnnr_ft_id: '',
                bnnr_ft_nome: '',
                bnnr_ft_status: false,
            },
        }
    },
    methods: {
        loadBannerPhoto() {
            this.$store.dispatch('loadBannerPhoto', this.bnnr_ft_id)
                .then(response => {
                    this.bannerphoto = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },

    },
    components: {
        formBannerPhoto: FormBannerPhotoComponent
    }
};
</script>

<style scoped>

</style>
