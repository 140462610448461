<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>

            <v-row>
                <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-select v-model="bannerphoto.banner_id" :items="loadBanners" item-text="bnnr_nome" item-value="bnnr_id" label="Selecione um Banner..." outlined></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field v-model="bannerphoto.bnnr_ft_link" label="Link" outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-select v-model="bannerphoto.bnnr_ft_link_target" :items="targets" label="Target" outlined></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-textarea v-model="bannerphoto.bnnr_ft_descricao" name="descricao" label="Descrição" outlined></v-textarea>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field v-model="bannerphoto.bnnr_ft_ordem" label="Ordem" outlined></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-switch color="lime" v-model="bannerphoto.bnnr_ft_status" label="Figurar"></v-switch>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                            <v-btn class="btn-salvar" color="success" @click="onSubmit">
                                {{btnLabel}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">

                    <v-col v-if="bannerphoto.bnnr_ft_foto || preview" class="justify-center">

                        <v-card class="justify-center" outlined>

                            <v-img v-if="preview" class="img-preview" :src="preview">
                            </v-img>

                            <v-img v-if="bannerphoto.bnnr_ft_foto && !preview" class="img-preview" :src="`${base_url}upload/banner_photo/${bannerphoto.bnnr_ft_foto}`">
                            </v-img>

                            <v-card-actions class="justify-center">
                                <input type="file" ref="file" style="display:none;" id="files" @change="onFileChange" />
                                <v-btn icon>
                                    <v-icon @click="$refs.file.click()">cloud_upload</v-icon>
                                </v-btn>
                                <v-btn icon>
                                    <v-icon @click.prevent="removePreview">highlight_off</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                    </v-col>

                    <v-col class="justify-center">
                        <v-btn v-if="!preview && !bannerphoto.bnnr_ft_foto" color="blue-grey" class="btn-upload white--text" @click="$refs.file.click()">
                            <v-icon class="mdi mdi-cloud-upload mr-2"></v-icon> Adicionar foto
                        </v-btn>
                        <input type="file" ref="file" style="display:none;" id="files" @change="onFileChange" />
                    </v-col>

                </v-col>
            </v-row>

        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    created() {
        this.$store.dispatch('loadBanners');
    },
    computed: {
        loadBanners() {
            if (this.$store.state.banners.items.data == null)
                return []

            return this.$store.state.banners.items.data;
        }
    },
    props: {
        bannerphoto: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    bnnr_ft_id: '',
                    banner_id: '',
                    bnnr_ft_link: '#',
                    bnnr_ft_link_target: '_blank',
                    bnnr_ft_descricao: '',
                    bnnr_ft_foto: '',
                    bnnr_ft_icone: 'icone.png',
                    bnnr_ft_click: '1',
                    bnnr_ft_ordem: '0',
                    bnnr_ft_status: false,
                }
            },
        },
        id: '',
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    name: "FormBannerComponent",
    data: () => ({
        targets: ['_blank', '_parent', '_self', '_top'],
        valid: true,
        preview: null,
        base_url: URL_BASE
    }),
    methods: {

        onSubmit() {

            const action = this.update ? 'updateBannerPhoto' : 'storeBannerPhoto';
            const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

            const formData = new FormData();

            if (this.upload !== null)
                formData.append('bnnr_ft_foto', this.upload);

            formData.append('bnnr_ft_id', this.bannerphoto.bnnr_ft_id);
            formData.append('banner_id', this.bannerphoto.banner_id);
            formData.append('bnnr_ft_link', this.bannerphoto.bnnr_ft_link);
            formData.append('bnnr_ft_link_target', this.bannerphoto.bnnr_ft_link_target);
            formData.append('bnnr_ft_descricao', this.bannerphoto.bnnr_ft_descricao);
            formData.append('bnnr_ft_ordem', this.bannerphoto.bnnr_ft_ordem);
            formData.append('bnnr_ft_status', this.bannerphoto.bnnr_ft_status);

            this.$store.dispatch(action, formData)
                .then(() => {
                    this.$router.push({
                        name: 'bannerphotos',
                        params: {
                            bnnr_id: this.bannerphoto.banner_id
                        }
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Algo deu errado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.errors = error.response.data.errors
                })
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;

            this.upload = files[0];
            this.fileName = this.upload.name;

            this.previewImage(files[0]);
        },
        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result;
            };
            reader.readAsDataURL(file)
        },
        removePreview() {
            this.preview = null;
            this.upload = null;
        },
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}
</style>
